export default class home {

	constructor() {
		
		this.initializePage();
		this.initializeNavigation();
		this.initializeIndex();
		this.initializeCatalogue();
		this.initializeCatalogueObservers();
		this.initializeInformation();
		this.checkCurrentPage();
		this.initializeMinimap();

		if(!this.isMobileDevice()){
			this.initializeTimers();
			setTimeout(() => {
				this.initializeMouse();
			}, 2000);
		}

		this.initializeKeyboard();

	}

	isMobileDevice() {
		return window.innerWidth <= 768;
	}

	isTouchDevice() {
		return 'ontouchstart' in window || navigator.maxTouchPoints;
	}

	initializePage() {

		setTimeout(() => {
			document.body.classList.remove('show-splash-screen');
			setTimeout(() => {
				document.body.classList.remove('show-title');
			}, 10);
		}, 2500);

		


		


		
		

	}

	initializeNavigation(){

		const nav = document.querySelector('nav');
		const mainTitlesInner = document.querySelector('.main-titles-inner');

		// main navigation hover
		if(!this.isTouchDevice()){
			
			let transitionTimeout;
			nav.addEventListener('mouseenter', () => {

				document.body.classList.remove('credits-open');
				
				mainTitlesInner.classList.add('transition-width');

				clearTimeout(transitionTimeout);
				clearTimeout(leaveTimeout);

				transitionTimeout = setTimeout(() => {
					mainTitlesInner.classList.add('transition-position');
					document.body.classList.add('show-title');
				}, 200);
			});

			let leaveTimeout;

			nav.addEventListener('mouseleave', () => {
				leaveTimeout = setTimeout(() => {
					if(nav.classList.contains('show-filters')){
						mainTitlesInner.classList.remove('transition-position');
						mainTitlesInner.classList.remove('transition-width');
						nav.classList.remove('show-filters');
						document.body.classList.remove('show-title');
					} else {
						mainTitlesInner.classList.remove('transition-position');
						clearTimeout(transitionTimeout);
						transitionTimeout = setTimeout(() => {
							mainTitlesInner.classList.remove('transition-width');
							document.body.classList.remove('show-title');
						}, 0);
					}
				}, 0);
			});


			// open filters
			const filterLink = document.querySelector('.current-filters');

			filterLink.addEventListener('click', () => {
				nav.classList.add('show-filters');
				setTimeout(() => {
					mainTitlesInner.classList.add('no-transitions');
					mainTitlesInner.classList.remove('transition-position');
					mainTitlesInner.classList.remove('transition-width');
					setTimeout(() => {
						mainTitlesInner.classList.remove('no-transitions');
					}, 100);
				}, 500);
			});

		} else {

			document.body.classList.add('touch');

			document.querySelector('.title-name').addEventListener('click', (e) => {

				document.body.classList.remove('credits-open');
				// document.querySelector('.main-titles').classList.remove('width-catalogue', 'width-index', 'width-information');
				document.body.classList.add('touch-show-main-menu');

				e.preventDefault();
			});

			document.querySelector('.current-filter').addEventListener('click', (e) => {

				document.body.classList.remove('credits-open');
				document.body.classList.add('touch-show-filter-menu');

				e.preventDefault();
			});
			
			document.querySelectorAll('section').forEach((section) => {
				section.addEventListener('click', (e) => {
					document.body.classList.remove('touch-show-main-menu');
					document.body.classList.remove('touch-show-filter-menu');
				});
			});


		}



		


		// page navigation
		document.querySelectorAll('.page-titles a').forEach((title) => {
			title.addEventListener('click', (e) => {
				const section = e.target.dataset.section;

				document.querySelectorAll('section').forEach((section) => {
					section.classList.remove('active-section');
					section.classList.add('inactive-section');
				});

				document.querySelector('.page-title').innerHTML = e.target.innerHTML;
				const mainTitles = document.querySelector('.main-titles');
				mainTitles.classList.remove('width-catalogue', 'width-index', 'width-information');
				mainTitles.classList.add('width-' + section);

				setTimeout(() => {
					this.updatePageURL('/' + section);
				}, 500);

				const filterText = document.querySelector('.current-filters');
				if(section === 'information'){
					setTimeout(() => {
						document.body.classList.add('information-open');
						filterText.dataset.width = filterText.offsetWidth;
						filterText.style.width = '0px';
						document.querySelector('nav').dispatchEvent(new Event('mouseleave'));
						
					}, 300);
				} else {
					document.body.classList.remove('information-open');
					if(filterText.dataset.width){
						filterText.style.width = filterText.dataset.width + 'px';
						setTimeout(() => {
							filterText.style.width = 'auto';
						}, 300);
					}
				}

				if(haveFiltersChanged === false){
					haveFiltersChanged = true;
					this.showAllProjects();
				}

				// reset catalogue
				setTimeout(() => {
					document.querySelectorAll('section').forEach((section) => {
						section.scrollTo({
							top: 0,
							behavior: 'instant'
						});
						document.querySelector('.mouse-next-slide').querySelector('.current-index').textContent = 1;
						document.querySelector('.mouse-next-project').querySelector('.current-index').textContent = 1;
						document.querySelector('.mouse-prev-slide').querySelector('.current-index').textContent = 1;
					});
					document.querySelector('.' + section + '-container').classList.remove('inactive-section');
					document.querySelector('.' + section + '-container').classList.add('active-section');
				}, 300);

				document.body.classList.remove('touch-show-main-menu');

				// setTimeout(() => {
				// 	this.resetURL();
				// }, 1000);

				e.preventDefault();
			});
		});


		// filter navigation
		let haveFiltersChanged = false;

		document.querySelectorAll('.filter-titles button').forEach((filter) => {
			filter.addEventListener('click', (e) => {

				const tag = filter.getAttribute('data-tag');
				const catalogueProjects = document.querySelectorAll('.catalogue-project');
				const minimapProjects = document.querySelectorAll('.minimap-project');
				const indexProjects = document.querySelectorAll('.index-project');

				haveFiltersChanged = true;
				
				// remove active class from all buttons
				document.querySelectorAll('.filter-titles button').forEach((button) => {
					button.classList.remove('active-filter');
				});
				filter.classList.add('active-filter');

				// fade the catalogue container
				const catalogueContainer = document.querySelector('.catalogue-container');
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					catalogueContainer.classList.remove('active-section');
					catalogueContainer.classList.add('inactive-section');

					setTimeout(() => {
						catalogueContainer.scrollTo({
							top: 0,
							behavior: 'instant'
						});
						document.querySelector('.minimap-inner').style.transform = `translateY(0px)`;
						catalogueContainer.classList.remove('inactive-section');
						catalogueContainer.classList.add('active-section');
					}, 350);
				}

				// filter the projects
				setTimeout(() => {
					catalogueProjects.forEach(project => {
						const projectTags = project.getAttribute('data-tags').split(',').map(tag => tag.trim());
		
						if (projectTags.includes(tag) || tag === 'All') {
							project.classList.remove('hidden-project');
						} else {
							project.classList.add('hidden-project');
						}
					});

					indexProjects.forEach(project => {
						const projectTags = project.getAttribute('data-tags').split(',').map(tag => tag.trim());
		
						if (projectTags.includes(tag) || tag === 'All') {
							project.classList.remove('hidden-project');
						} else {
							project.classList.add('hidden-project');
						}
					});

					minimapProjects.forEach(project => {
						const projectTags = project.getAttribute('data-tags').split(',').map(tag => tag.trim());
		
						if (projectTags.includes(tag) || tag === 'All') {
							project.classList.remove('hidden-project');
						} else {
							project.classList.add('hidden-project');
						}
					});

					document.body.classList.remove('touch-show-filter-menu');

				}, 300);

				document.querySelector('.current-filter').innerHTML = tag;
	
				e.preventDefault();
			});
		});


	}



	initializeIndex(){
	
		document.querySelectorAll('.index-project').forEach((item) => {
			item.addEventListener('click', (e) => {
				item.classList.add('clicked-item');
				setTimeout(() => {
					item.classList.remove('clicked-item');
				}, 300);

				const id = item.dataset.id;
				const catalogueProject = document.querySelector('.catalogue-project[data-id="' + id + '"]');
				const projectOffset = catalogueProject.offsetTop;

				document.querySelector('.catalogue-container').scrollTo({
					top: projectOffset,
					behavior: 'instant'
				});

				document.querySelectorAll('section').forEach((section) => {
					section.classList.remove('active-section');
					section.classList.add('inactive-section');
				});

				document.body.classList.remove('information-open');

				document.querySelector('.page-title').innerHTML = 'Catalogue';

				const mainTitles = document.querySelector('.main-titles');
				mainTitles.classList.remove('width-catalogue', 'width-index', 'width-information');

				mainTitles.classList.add('width-catalogue');

				setTimeout(() => {
					document.querySelector('.catalogue-container').classList.remove('inactive-section');
					document.querySelector('.catalogue-container').classList.add('active-section');
				}, 300);
			});
		});
	
	}



	initializeCatalogue(){
		
		document.querySelector('.catalogue-container').addEventListener('scroll', function() {
			const catalogueScrollTop = this.scrollTop;
			const catalogueScrollHeight = this.scrollHeight;
			const viewportHeight = window.innerHeight;
		
			// Calculate the scroll proportion
			const scrollProportion = catalogueScrollTop / (catalogueScrollHeight - viewportHeight);
		
			const minimap = document.querySelector('.minimap-container');
			const minimapHeight = minimap.clientHeight;
			const minimapInner = document.querySelector('.minimap-inner');
			const minimapInnerHeight = minimapInner.scrollHeight;
		
			const translateValue = -(scrollProportion * (minimapInnerHeight - minimapHeight));
		
			minimapInner.style.transform = `translateY(${translateValue}px)`;
		
			// Determine the active catalogue project more dynamically
			const catalogueProjects = document.querySelectorAll('.catalogue-project');
			let activeProjectIndex = 0;
			
			for (let i = 0; i < catalogueProjects.length; i++) {
				// Get the top position of the project relative to the viewport
				const projectTop = catalogueProjects[i].getBoundingClientRect().top;
				
				// Update active project if the top of the project is within half of the viewport height
				if (projectTop <= viewportHeight / 2) {
					activeProjectIndex = i;
				} else {
					break; // Break the loop once the project top is below the middle of the viewport
				}
			}

		});
		

		// prev/next arrows

		if(!this.isTouchDevice()){

			document.querySelectorAll('.catalogue-slide').forEach((slide) => {
				
				slide.addEventListener('click', (e) => {
					if(document.body.classList.contains('show-arrow-left') && !document.body.classList.contains('credits-open')){
						const project = slide.parentElement;
						const slideIndex = Array.from(project.children).indexOf(slide);
						const totalSlides = project.children.length;
						const nextSlide = project.children[slideIndex - 1] || project.children[totalSlides - 1];
						const nextSlideOffset = nextSlide.offsetLeft;
						
						project.scrollTo({
							left: nextSlideOffset,
							behavior: 'smooth'
						});

					}

					if(document.body.classList.contains('show-arrow-right') && !document.body.classList.contains('credits-open')){
						
						const project = slide.parentElement;
						const slideIndex = Array.from(project.children).indexOf(slide);
						const nextSlide = project.children[slideIndex + 1] || project.children[0];
						const nextSlideOffset = nextSlide.offsetLeft;
						project.scrollTo({
							left: nextSlideOffset,
							behavior: 'smooth'
						});

					}

				});

			});

			document.querySelectorAll('.catalogue-project').forEach((project) => {
				project.addEventListener('click', (e) => {
					if(document.body.classList.contains('show-arrow-down') && !document.body.classList.contains('credits-open')){
						let currentProject = project; // Starting point for the search
						let nextProject = currentProject.nextElementSibling; // Attempt to get the next sibling
				
						// Loop to find the next project without 'hidden-project' class
						while (nextProject && nextProject.classList.contains('hidden-project')) {
							nextProject = nextProject.nextElementSibling; // Move to the next sibling
						}
				
						// If nextProject is found, determine if it's actually before the current item in document flow
						if (nextProject) {
							const currentProjectOffset = currentProject.offsetTop;
							const nextProjectOffset = nextProject.offsetTop;
				
							// Check if the next project is before the current project
							if (nextProjectOffset <= currentProjectOffset) {
								// If so, set the scroll offset to 0
								document.querySelector('.catalogue-container').scrollTo({
									top: 0,
									behavior: 'smooth'
								});
							} else {
								// Scroll to the next project's top offset
								document.querySelector('.catalogue-container').scrollTo({
									top: nextProjectOffset,
									behavior: 'smooth'
								});
							}
						} else {
							// If no next project is found or the next project is before the current project, scroll to top
							document.querySelector('.catalogue-container').scrollTo({
								top: 0,
								behavior: 'smooth'
							});
						}
					}

					if(document.body.classList.contains('show-arrow-up') && !document.body.classList.contains('credits-open')){
						let currentProject = project; // Starting point for the search
						let previousProject = currentProject.previousElementSibling; // Attempt to get the previous sibling

						while (previousProject && previousProject.classList.contains('hidden-project')) {
							previousProject = previousProject.previousElementSibling; // Move to the previous sibling
						}

						if (!previousProject) {
							document.querySelectorAll('.catalogue-project').forEach((project) => {
								if (!project.classList.contains('hidden-project')) {
									previousProject = project;
								}
							});
						}
						
						if (previousProject && previousProject !== currentProject) {
							const previousProjectOffset = previousProject.offsetTop;
							document.querySelector('.catalogue-container').scrollTo({
								top: previousProjectOffset,
								behavior: 'smooth'
							});
						}

					}
				});
				
				
			});

		}

		// credits button
		document.querySelector('.credits-button').addEventListener('click', (e) => {

			if(document.body.classList.contains('credits-open')){
				document.body.classList.remove('credits-open');
			} else {
				document.body.classList.add('credits-open');
			}

			e.preventDefault();
		});

	}

	initializeMinimap(remove = false){

		if(remove){
			document.querySelector('.minimap-container').remove();
		}

		const minimap =	document.createElement('div');
		minimap.classList.add('minimap-container');
		const minimapInner = document.createElement('div');
		minimapInner.classList.add('minimap-inner');

		document.querySelectorAll('.catalogue-project').forEach((project) => {
			let row = document.createElement('div');
			row.classList.add('minimap-project');

			if(project.classList.contains('hidden-project')){
				row.classList.add('hidden-project');
			}
			row.dataset.id = project.dataset.id;
			row.dataset.tags = project.dataset.tags;
			row.dataset.title = project.dataset.title;
			row.dataset.slug = project.dataset.slug;

			let rowTitle = document.createElement('div');
			rowTitle.classList.add('minimap-project-title');
			rowTitle.innerHTML = project.dataset.title + ' <span>for ' + project.dataset.client + '</span>';
			

			project.querySelectorAll('.catalogue-slide').forEach((slide, index) => {

				let miniSlide = document.createElement('div');
				miniSlide.classList.add('minimap-slide');
				miniSlide.dataset.id = slide.dataset.id;

				const images = slide.querySelectorAll('.catalogue-image');

				images.forEach((image) => {
					let orient = '';
					if(image.classList.contains('slide-portrait')){
						orient = 'portrait';
					} else if(image.classList.contains('slide-landscape')){
						orient = 'landscape';
					} else {
						orient = 'square';
					}

					let miniImage = document.createElement('div');
					miniImage.classList.add('minimap-image');
					miniImage.classList.add('minimap-' + orient);

					let miniImageFile = document.createElement('img');
					let imageSrc = image.querySelector('img') ? image.querySelector('img').dataset.thumb : image.querySelector('video').dataset.thumb;
					miniImageFile.alt = project.alt;
					miniImageFile.src = imageSrc;
					miniImage.appendChild(miniImageFile);

					miniSlide.appendChild(miniImage);
				});

				row.appendChild(miniSlide);
			});

			row.appendChild(rowTitle);
			minimapInner.appendChild(row);
		});

		minimap.appendChild(minimapInner);
		document.querySelector('.catalogue-container').appendChild(minimap);

		document.querySelectorAll('.minimap-slide').forEach((slide) => {

			slide.addEventListener('mouseenter', (e) => {

				const projectIndex = Array.from(slide.parentElement.parentElement.children).indexOf(slide.parentElement);
				const slideIndex = Array.from(slide.parentElement.children).indexOf(slide);
				const project = document.querySelectorAll('.catalogue-project')[projectIndex];
				const slideOffset = project.querySelectorAll('.catalogue-slide')[slideIndex].offsetLeft;

				if(!document.body.classList.contains('credits-open')){
					project.scrollTo({
						left: slideOffset,
						behavior: 'instant'
					});
				}

			});
		});

		
	}


	initializeInformation(){
		const moreButton = document.querySelector('.more');
		if(moreButton){
			moreButton.addEventListener('click', (e) => {
				document.querySelector('.information-container').classList.toggle('show-more');
				e.preventDefault();
			});
		}
	}


	initializeTimers(){
		function setTimer(delay, callback) {
			return setTimeout(callback, delay);
		}

		function clearTimer(timerId) {
			clearTimeout(timerId);
		}

		let hideInterfaceTimer;
		let showMinimapTimer;

		function resetHideInterfaceTimer() {
			clearTimer(hideInterfaceTimer);
			if(document.querySelector('.catalogue-container').classList.contains('active-section')){
				document.body.classList.remove('hide-interface');
			}
			hideInterfaceTimer = setTimer(3000, () => {
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					document.body.classList.add('hide-interface');
				}
				// document.body.classList.remove('show-minimap-thumbnails');
			});
		}

		// Set initial hide interface timer
		resetHideInterfaceTimer();

		// Mouse movement event
		document.addEventListener('mousemove', resetHideInterfaceTimer);

		// Hover event on minimap-container
		document.querySelector('.minimap-container').addEventListener('mouseenter', () => {
			clearTimer(showMinimapTimer);
			document.body.classList.add('show-minimap-thumbnails');
		});
		// trigger on credits button too
		document.querySelector('.credits-button').addEventListener('mouseenter', () => {
			clearTimer(showMinimapTimer);
			document.body.classList.add('show-minimap-thumbnails');
		});

		// Mouseleave event on minimap-container
		document.querySelector('.minimap-container').addEventListener('mouseleave', () => {
			// Set a timer to remove 'show-minimap-thumbnails' class after 2 seconds
			showMinimapTimer = setTimer(1000, () => {
				document.body.classList.remove('show-minimap-thumbnails');
			});
		});



		// ON SCROLL SHOW MINIMAP THUMBNAILS

		let scrollTimer;

		// Function to handle scrolling on catalogue-container
		function onCatalogueScroll() {
			// Clear any existing timer for scrolling
			clearTimer(scrollTimer);
			resetHideInterfaceTimer();

			// Add 'show-minimap-thumbnails' while scrolling
			// document.body.classList.add('scroll-show-minimap-thumbnails');

			// Set a timer to remove 'show-minimap-thumbnails' 1 second after scrolling stops
			scrollTimer = setTimer(500, () => {
				// document.body.classList.remove('scroll-show-minimap-thumbnails');
			});
		}

		// Add scroll event listener to catalogue-container
		document.querySelector('.catalogue-container').addEventListener('scroll', onCatalogueScroll);

	}


	initializeMouse(){
		const follower = document.querySelector('.mouse-follower');
		let mouseX = window.innerWidth/2, mouseY = window.innerHeight/2;

		let windowWidth = window.innerWidth;
		let windowHeight = window.innerHeight;
		setInterval(() => {
			windowWidth = window.innerWidth;
			windowHeight = window.innerHeight;
		}, 1000);

		document.addEventListener('mousemove', (e) => {
			mouseX = e.clientX;
			mouseY = e.clientY;
		});

		function updateFollowerPosition() {
			follower.style.transform = `translate(${mouseX}px, ${mouseY}px)`;

			const leftArea = windowWidth * 0.35;
			const rightArea = windowWidth * 0.65;
			const bottomArea = windowHeight - 120;
			const topArea = 120;

			if (mouseX < leftArea && mouseY < bottomArea && mouseY > topArea){
				document.body.classList.remove('show-arrow-right');
				document.body.classList.remove('show-arrow-down');
				document.body.classList.add('show-arrow-left');
				document.body.classList.add('show-arrow-minimap-thumbnails');
			} else if(mouseX > rightArea && mouseY < bottomArea && mouseY > topArea){
				document.body.classList.remove('show-arrow-left');
				document.body.classList.remove('show-arrow-down');
				document.body.classList.add('show-arrow-right');
				document.body.classList.add('show-arrow-minimap-thumbnails');
			} else if(mouseX > leftArea && mouseX < rightArea && mouseY < bottomArea && mouseY > topArea){
				document.body.classList.remove('show-arrow-left');
				document.body.classList.remove('show-arrow-right');
				document.body.classList.add('show-arrow-down');
				document.body.classList.remove('show-arrow-minimap-thumbnails');
			} else {
				document.body.classList.remove('show-arrow-left');
				document.body.classList.remove('show-arrow-right');
				document.body.classList.remove('show-arrow-down');
				document.body.classList.remove('show-arrow-minimap-thumbnails');
			}
		}

		if (window.requestAnimationFrame) {
			function animate() {
				updateFollowerPosition();
				requestAnimationFrame(animate);
			}
			requestAnimationFrame(animate);
		}
	}

	updateArrowCounts(type, element){
		if(type === 'slide'){
			const totalSlides = element.parentElement.querySelectorAll('.catalogue-slide').length;
			const currentSlideIndex = Array.from(element.parentElement.children).indexOf(element) + 1;

			document.querySelector('.mouse-next-slide').querySelector('.current-index').textContent = currentSlideIndex;
			document.querySelector('.mouse-next-slide').querySelector('.total-index').textContent = totalSlides;
			document.querySelector('.mouse-prev-slide').querySelector('.current-index').textContent = currentSlideIndex;
			document.querySelector('.mouse-prev-slide').querySelector('.total-index').textContent = totalSlides;
		} else if(type === 'project'){
			const totalProjects = document.querySelectorAll('.catalogue-project:not(.hidden-project)').length;
			const projects = document.querySelectorAll('.catalogue-project:not(.hidden-project)');
			let currentProjectIndex = -1;
			projects.forEach((project, index) => {
				if (project.classList.contains('active-project')) {
					currentProjectIndex = index + 1;
				}
			}
			);
			document.querySelector('.mouse-next-project').querySelector('.current-index').textContent = currentProjectIndex;
			document.querySelector('.mouse-next-project').querySelector('.total-index').textContent = totalProjects;
		}

		
	}
	
	initializeCatalogueObservers(){

		document.addEventListener('DOMContentLoaded', () => {
			// Observer callback for slides, to handle horizontal visibility
			const slideObserverCallback = entries => {
			  entries.forEach(entry => {
				// Ensure we only consider slides within the currently active project
				if (entry.isIntersecting) {
					const activeSlide = entry.target;
					const activeSlideId = activeSlide.dataset.id;
					const activeProject = activeSlide.closest('.catalogue-project');
					
					const activeSlides = activeSlide.closest('.catalogue-project').querySelectorAll('.active-slide');
					activeSlides.forEach(slide => {
						if (slide !== activeSlide) {
							slide.classList.remove('active-slide');
							document.querySelector('.minimap-slide[data-id="' + slide.dataset.id + '"]').classList.remove('active-minimap-slide');
						}
				  	});
				  
				  	// Activate the current slide
				  	activeSlide.classList.add('active-slide');
					document.querySelector('.minimap-slide[data-id="' + activeSlideId + '"]').classList.add('active-minimap-slide');
					setTimeout(() => {
						this.updateArrowCounts('slide', activeSlide);
					}, 100);
				}
			  });
			//   hide credits on change slide
			  document.body.classList.remove('credits-open');
			};
		  
			// Setup slide observer with options for horizontal visibility
			const slideObserver = new IntersectionObserver(slideObserverCallback, {
				root: null, // Observing within the viewport
				rootMargin: '0px',
				threshold: 0.5 // Adjust based on when you consider a slide "active"
			});
		  
			// Attach slide observers to all slides
			document.querySelectorAll('.catalogue-slide').forEach(slide => {
			  	slideObserver.observe(slide);
			});
		  
			// Observer callback for projects, to handle vertical scrolling and project activation
			const projectObserverCallback = entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						const activeProject = entry.target;
						const activeProjectId = activeProject.dataset.id;
						activeProject.classList.add('active-project');
						document.querySelector('.minimap-project[data-id="' + activeProjectId + '"]').classList.add('active-minimap-project');
						setTimeout(() => {
							this.updateArrowCounts('project', activeProject);
							if(!this.isTouchDevice() && document.querySelector('.catalogue-container').classList.contains('active-section')){
								this.updateProjectURL(activeProject);
							}
							if(activeProject.querySelector('.catalogue-project-credits')){
								document.body.classList.add('show-credits-button');
							} else {
								document.body.classList.remove('show-credits-button');
							}
						}, 100);
					} else {
						const inactiveProject = entry.target;
						const inactiveProjectId = inactiveProject.dataset.id;
						inactiveProject.classList.remove('active-project');
						document.querySelector('.minimap-project[data-id="' + inactiveProjectId + '"]').classList.remove('active-minimap-project');
					}
				});

				// hide credits on change project
				document.body.classList.remove('credits-open');
			};
		  
			// Setup project observer for vertical movement
			const projectObserver = new IntersectionObserver(projectObserverCallback, {
				root: document.querySelector('.catalogue-container'),
				rootMargin: '0px',
				threshold: 0.5 // Adjust based on when you consider a project "active"
			});
		  
			// Start observing each project
			document.querySelectorAll('.catalogue-project').forEach(project => {
			  	projectObserver.observe(project);
			});

		});
		  
	}

	initializeKeyboard(){
		document.addEventListener('keydown', (e) => {
			if(e.key === 'ArrowDown'){
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					document.body.classList.add('show-arrow-down');
					document.querySelector('.active-project').click();
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					e.preventDefault();
				}
			} else if(e.key === 'ArrowUp'){
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					document.body.classList.add('show-arrow-up');
					document.querySelector('.active-project').click();
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					e.preventDefault();
				}
			} else if(e.key === 'ArrowLeft'){
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					document.body.classList.add('show-arrow-left');
					document.querySelector('.active-project .active-slide').click();
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					e.preventDefault();
				}
			} else if(e.key === 'ArrowRight'){
				if(document.querySelector('.catalogue-container').classList.contains('active-section')){
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					document.body.classList.add('show-arrow-right');
					document.querySelector('.active-project .active-slide').click();
					document.body.classList.remove('show-arrow-left', 'show-arrow-right', 'show-arrow-down', 'show-arrow-up');
					e.preventDefault();
				}
			}
		});
	}

	showAllProjects(){
		document.querySelector('.current-filter').innerHTML = 'all';
		document.querySelectorAll('.filter-titles li').forEach((filter) => {
			filter.classList.remove('active');
			if(filter.innerHTML === 'all'){
				filter.classList.add('active');
			}
		});
		document.querySelectorAll('.catalogue-project').forEach(project => {
			project.classList.remove('hidden-project');
		});
		document.querySelectorAll('.minimap-project').forEach(project => {
			project.classList.remove('hidden-project');
		});
	}

	resetURL(){
		const siteURL = document.body.dataset.url;
		history.replaceState('catalogue', '', siteURL);
	}

	updatePageURL(page){
		const siteURL = document.body.dataset.url + page;
		history.replaceState(page, '', siteURL);
		// this.resetURL();
	}

	updateProjectURL(project){
		const projectSlug = project.dataset.slug;
		const projectURL = project.dataset.url;
		const siteURL = document.body.dataset.url;

		history.replaceState(null, '', projectURL);
	
	}

	checkCurrentPage(){
		// if its a information permalink
		if(document.body.classList.contains('information-open')){
			document.querySelector('.page-title').innerHTML = 'Information';
			const mainTitles = document.querySelector('.main-titles');
			mainTitles.classList.remove('width-catalogue', 'width-index',  'width-information');
			mainTitles.classList.add('width-information');

			const filterText = document.querySelector('.current-filters');
			setTimeout(() => {
				filterText.dataset.width = filterText.offsetWidth;
				filterText.style.width = '0px';
			}, 300);
		}

		// if its a index permalink
		if(document.body.classList.contains('index-open')){
			this.showAllProjects();
			document.querySelector('.page-title').innerHTML = 'Index';
			const mainTitles = document.querySelector('.main-titles');
			mainTitles.classList.remove('width-catalogue', 'width-index',  'width-information');
			mainTitles.classList.add('width-index');

			// const filterText = document.querySelector('.current-filters');
			// setTimeout(() => {
			// 	filterText.dataset.width = filterText.offsetWidth;
			// 	filterText.style.width = '0px';
			// }, 300);
		}

		// if its a permalink single project
		if(document.body.classList.contains('is-single')){
			this.showAllProjects();
			const slug = document.body.dataset.slug;
			const catalogueProject = document.querySelector('.catalogue-project[data-slug="' + slug + '"]');
			const projectOffset = catalogueProject.offsetTop;

			document.querySelector('.catalogue-container').scrollTo({
				top: projectOffset,
				behavior: 'instant'
			});
		}

	}
}